@import '@styles/mixins.style';

$viewport: 100vw;
$lgContainer: 1200px;
$xlContainer: 1512px;

.ra-toaster {
  position: fixed;
  transition: all 350ms ease;
  display: flex;
  align-items: center;
  pointer-events: none;
  background-color: var(--color-bg-light);
  border-radius: var(--ra-border-radius-xl);
  box-shadow: 0px 12px 52px 0px rgba(0, 0, 0, 0.35),
    0px 8px 16px 0px rgba(0, 0, 0, 0.29);
  transform: translateY(-300%);
  padding: var(--ra-spacing-4);
  gap: var(--ra-spacing-4);
  z-index: 999;
  top: var(--ra-spacing-5);
  right: 16px;
  left: 16px;

  @include breakpoint(sm) {
    left: auto;
    right: 40px;
    min-width: 230px;
    max-width: 300px;
  }

  @include breakpoint(lg) {
    right: calc(($viewport - $lgContainer) / 2);
  }

  @include breakpoint(xl) {
    right: calc(($viewport - $xlContainer) / 2);
  }

  &.open {
    transform: translateY(100px);
    opacity: 1;
    pointer-events: inherit;
  }
}
